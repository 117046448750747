import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

import {
  DeserializedAppCurrentUserMeta,
  getProjectValidationList,
  normalizeProjectValidationSearchParams,
  PROJECT_VALIDATION_LIST_PAGE_LIMIT,
  ProjectValidationListPageSearchParams,
  ProjectValidationListSearchParams,
  registerUserVote,
} from '@api';

import { appQueryKeys } from './app';

const projectValidationQueryKeys = {
  projectValidationList: (params?: ProjectValidationListSearchParams) => [
    'projectsValidation',
    params,
  ],
  projectValidationInfiniteList: (params?: ProjectValidationListSearchParams) => [
    'projectValidationInfiniteList',
    params,
  ],
};

export const prefetchProjectValidationListQuery = (
  clientQuery: QueryClient,
  params?: Parameters<typeof getProjectValidationList>[0],
) => {
  return clientQuery.prefetchQuery({
    queryKey: projectValidationQueryKeys.projectValidationList(params?.searchParams),
    queryFn: () => getProjectValidationList(params),
  });
};

export const useProjectValidationListQuery = (
  params?: ProjectValidationListSearchParams,
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getProjectValidationList>>,
      unknown,
      unknown,
      any
    >,
    'placeholderData'
  >,
) => {
  return useQuery({
    queryKey: projectValidationQueryKeys.projectValidationList(params),
    queryFn: () =>
      getProjectValidationList({
        searchParams: params,
      }),
    ...options,
  });
};

export const useProjectValidationListInfiniteQuery = (
  params?: ProjectValidationListPageSearchParams,
) => {
  const searchParams = normalizeProjectValidationSearchParams(params);

  return useInfiniteQuery({
    queryKey: projectValidationQueryKeys.projectValidationInfiniteList(searchParams),
    queryFn: ({ pageParam: offset }) =>
      getProjectValidationList({
        searchParams: {
          ...searchParams,
          offset,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) =>
      lastPage?.rows.length === PROJECT_VALIDATION_LIST_PAGE_LIMIT
        ? pages.length * PROJECT_VALIDATION_LIST_PAGE_LIMIT
        : undefined,
  });
};

export const useRegisterUserVoteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      options: Parameters<typeof registerUserVote>[0] & {
        json: { appId: number; appLink: string };
        headers?: {
          'x-sign-in'?: 'v2';
        };
      },
    ) => registerUserVote(options),
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: appQueryKeys.appValidationInfiniteVoterList(params.json.appLink),
      });

      queryClient.setQueryData(
        appQueryKeys.appCurrentUserMeta(params.json.appLink),
        (oldData?: DeserializedAppCurrentUserMeta) => {
          if (!oldData) {
            return oldData;
          }

          return {
            ...oldData,
            attributes: {
              ...oldData.attributes,
              voted: true,
            },
          };
        },
      );
    },
  });
};
