import { QueryClient, useInfiniteQuery } from '@tanstack/react-query';

import { GET_PERKS_LIST_DEFAULT_LIMIT, getPerksList, GetPerksListSearchParams } from '@api';

const perksQueryKeys = {
  infiniteList: (params: GetPerksListSearchParams = {}) => ['perksInfiniteList', params],
};

export const usePerksListInfinityQuery = (
  params: Omit<GetPerksListSearchParams, 'offset' | 'limit'> = {},
) => {
  return useInfiniteQuery({
    queryKey: perksQueryKeys.infiniteList(params),
    queryFn: ({ pageParam: offset }) =>
      getPerksList({
        searchParams: {
          ...params,
          offset,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) =>
      lastPage?.rows.length === GET_PERKS_LIST_DEFAULT_LIMIT
        ? pages.length * GET_PERKS_LIST_DEFAULT_LIMIT
        : undefined,
  });
};

export const prefetchPerksListInfiniteQuery = async (
  clientQuery: QueryClient,
  params?: Parameters<typeof getPerksList>[0],
) => {
  const queryKey = perksQueryKeys.infiniteList(params?.searchParams);

  await clientQuery.prefetchInfiniteQuery({
    queryKey,
    queryFn: () => getPerksList(params),
    initialPageParam: 0,
  });
};
