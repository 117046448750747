import { useQuery } from '@tanstack/react-query';
import { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

import { getReviewReportTypes } from '@api';

export const reviewQueryKeys = {
  reportTypes: ['reportTypes'],
};

export const useReviewReportTypesQuery = (
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getReviewReportTypes>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: reviewQueryKeys.reportTypes,
    queryFn: () => getReviewReportTypes(),
    ...options,
  });
};
